import { CustomNumberBox, CustomSimpleTextArea } from "components/CustomDataGridComponents";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import DataGrid, {
  Column,
  Editing,
  LoadPanel,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  AsyncRule,
} from "devextreme-react/data-grid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import RadioGroup from "devextreme-react/radio-group";
import { toast } from "react-toastify";
import { CustomTooltip } from "components/CustomDataGridComponents";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { createSanitizeAsyncRule } from "utils/services/Helpers";
import CloseIcon from '@mui/icons-material/Close';

export default function ManageClientVitalGrid({
  rows,
  columns,
  dropDownData,
  defaultKey = "id",
  isLoading,
  permissions,
  allowAdding = true,
  allowUpdating = true,
  postData,
  colors,
  categoryData,
  masterClientId,
  masterDepartmentId,
  hasDataChanged,
  setHasDataChanged
}) {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [invalidRowKeys, setInvalidRowKeys] = useState([]);
  const dataGridRef = useRef();
  let navigate = useNavigate();
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");
  const TEXT_LENGTH_THRESHOLD = 84;

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);
    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  const renderColorsHeader = (dataIndex) => {
    let value = null;
    if (dataIndex.startsWith("masterCvLightId-")) value = parseInt(dataIndex.split("-")?.[1]);
    const selectedColors = value ? colors.filter(c => c.masterCVTrafficLightId === value).map(e => {
      return {
        color: e.color,
        description: e.description,
      };
    })
      : colors.map((e) => {
        return {
          color: e.color,
          description: e.description,
        };
      });
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "1.5rem", padding: "0 14px" }}>
        {selectedColors.map(({ color, description }, index) => {
          return (
            <li
              title={description}
              key={`${color}-${index}`}
              style={{
                listStyle: "none",
                borderRadius: "50%",
                backgroundColor: color,
                width: "22px",
                height: "22px",
                border: "1px solid #645f5f70",
              }}
            ></li>
          );
        })}
      </div>
    );
  };

  function renderEditColorCell(props, editMode, required) {
    let value = null;
    if (props.column.dataField.startsWith("masterCvLightId-")) {
      value = parseInt(props.column.dataField.split("-")?.[1]);
    }

    useEffect(() => {
      // Only attach event listeners if the cell is not required
      if (!required) {
        const radioGroups = document.querySelectorAll(`.dx-radiogroup.__not__required__${props.rowIndex}`);

        radioGroups.forEach((radioGroup) => {
          const radioButtons = radioGroup.querySelectorAll('.dx-radiobutton');

          const handleClick = (event) => {
            const clickedRadioButton = event.currentTarget;
            const radioIndex = Array.from(radioButtons).indexOf(clickedRadioButton);
            const parentElement = clickedRadioButton.parentElement;
            const grandparentElement = parentElement.parentElement;
            const hiddenInput = grandparentElement.querySelector('input[type="hidden"]');
            let radioValue = null
            setTimeout(() => {
              radioValue = parseInt(hiddenInput?.value)
            }, 200)

            if (radioValue && radioValue === props.value) {
              // Uncheck if the same option is clicked
              props.setValue(null);
              props.value = null
              props.data[`comments-${value}`] = null
              props.data[`changed-${value}`] = true;
              setDataSource(prev => prev.map(obj => obj.id === props.data.id ? { ...obj, [`masterCvLightId-${value}`]: null, [`comments-${value}`]: null } : obj));
              clickedRadioButton.setAttribute('aria-checked', 'false');
              radioValue = ''
            } else {
              // Select the clicked option
              props.setValue(radioValue);
              props.value = radioValue
              props.data[`comments-${value}`] = null
              props.data[`changed-${value}`] = true;
              radioButtons.forEach((radioButton, index) => {
                radioButton.setAttribute('aria-checked', index === radioIndex ? 'true' : 'false');
              });
            }
          };

          // Attach click event listener to each radio button
          radioButtons.forEach((radioButton) => {
            radioButton.addEventListener('click', handleClick);
          });

          // Cleanup listeners on unmount
          return () => {
            radioButtons.forEach((radioButton) => {
              radioButton.removeEventListener('click', handleClick);
            });
          };
        });
      }
    }, [props.value, props.rowIndex, required]);

    // const options = value ? colors.filter(c => c.masterCVTrafficLightId === value) : colors

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RadioGroup
          className={required ? "" : `__not__required__${props.rowIndex}`}
          items={value ? colors.filter(c => c.masterCVTrafficLightId === value) : colors}
          defaultValue={props.value}
          onValueChanged={(e) => {
            console.log("value", e, value);
            if (editMode) {
              props.setValue(e.value !== e.previousValue ? e.value : null);
              if (e.value !== e.previousValue) props.data[`changed-${value}`] = true
              if (!required) {
                props.data[`changed-${value}`] = true;
                props.data[`comments-${value}`] = null
              }
            }
          }}
          hoverStateEnabled={true}
          displayExpr=""
          valueExpr="id"
          layout="horizontal"
        />
        {/* {!required && (
          <IconButton
            size="small"
            onClick={handleReset}
            style={{
              padding: 0,
              marginLeft: '2px',
              width: '0px',
              height: '16px'
            }}
          >
            <CloseIcon fontSize="small" style={{ width: '15px', color: 'red' }} />
          </IconButton>
        )} */}
      </div>
    );
  }

  function renderCategoryItem(props) {
    let itemName = null,
      itemDescription = null;

    if (props && props.value) {
      itemName = props.value;
      if (props.data && props.data.masterCvCategoryId && props.data.masterCvItemId && dropDownData && dropDownData.masterCvCategoryId) {
        itemDescription = dropDownData.masterCvCategoryId
          .find(cat => cat.id === props.data.masterCvCategoryId)?.master_cv_items
          .find(item => item.id === props.data.masterCvItemId)?.description;
      }
    }

    if (itemName && itemDescription)
      return (
        <div> {itemName}
          <CustomTooltip title={
            itemDescription?.split(/("the client"|"the agency")/g)
              .map((part, index) => part === '"the client"' ? <strong key={index}>{masterClientId}</strong> :
                part === '"the agency"' ? <strong key={index}>{masterDepartmentId}</strong> :
                  part)
          }
            className="categoryItemTooltip">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </CustomTooltip>
        </div>
      );
    else if (itemName)
      return (
        <div>{itemName}</div>
      );
  }

  const CustomCell = (cellInfo) => {
    const textLength = cellInfo.value ? cellInfo.value.split(/\s+/).length : 0;
    const isLongText = textLength > TEXT_LENGTH_THRESHOLD || (cellInfo.value && cellInfo.value.length > 100);

    return (
      <div className={`custom-cell-content ${isLongText ? 'scrollable' : ''}`}>
        {cellInfo.value}
      </div>
    );
  };

  function renderField(col) {
    if (col.type === "actions") {
      return (
        <Column
          allowEditing={col.editable}
          visible={false}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          type="buttons"
          dataField={col.dataIndex}
          caption={col.title}
          fixed={false}
        ></Column>
      );
    }
    else if (col.type === "string") {
      return (
        <Column
          // width={"auto"}
          // minWidth={"100%"}
          bestFitWidth={true}
          allowEditing={false}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={renderCategoryItem}>
        </Column>
      );
    }
    else if (col.type === "radio-group") {
      return (
        <Column
          width={"auto"}
          visible={col.is_visible}
          cellRender={(props) => renderEditColorCell(props.data, false, col.required)}
          headerCellRender={() => renderColorsHeader(col.dataIndex)}
          caption={col.title}
          allowEditing={col.editable}
          dataField={col.dataIndex}
          allowSorting={false}
          allowSearch={false}
          allowFiltering={false}
          alignment={"left"}
          showEditorAlways={true}
          editCellComponent={(props) => renderEditColorCell(props.data, true, col.required)}
        >
        </Column>

      );
    }
    else if (col.type === "int") {
      return (
        <Column
          // width={"auto"}
          // minWidth={"250"}
          // bestFitWidth={true}
          dataType={col.type}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          editCellComponent={(props) => (
            <CustomNumberBox props={props.data} canEdit={col.editable} />
          )}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
    else if (col.type === "textarea") {
      return (
        <Column
          // encodeHtml={col.encodeHtml}
          wordWrapEnabled={true}
          minWidth={'100%'}
          editCellComponent={CustomSimpleTextArea}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={CustomCell}
        ><AsyncRule {...sanitizeAsyncRule} /></Column>
      );
    }
    else {
      return (
        <Column
          width={"auto"}
          minWidth={"250"}
          bestFitWidth={true}
          allowEditing={false}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
  }

  /**
   * function use to call the api to post data
   **/
  const pushData = async () => {
    const invalidRows = [];

    // dataSource.forEach(row => {
    //   ['masterCvLightId-', 'comments-'].forEach(prefix => {
    //     Array.from({ length: dataColumns.filter(col => col.dataIndex.startsWith(prefix)).length }, (_, i) => i + 1)
    //       .forEach(index => {
    //         const key = `${prefix}${index}`;
    //         row[key] ??= null;
    //       });
    //   });
    // });

    // console.log("dataSource", dataSource);
    // console.log("dataColumns", dataColumns);

    // Check if all masterCvLight- columns are required and if they are null
    const isCvLightSelected = dataSource.every(row =>
      dataColumns.filter(key => key?.dataIndex?.startsWith('masterCvLightId-')).every(column => {
        // console.log('row', row)
        // console.log('column', column)
        // console.log('column.required', column.required)
        // console.log('row[dataIndex] !== null', row[column.dataIndex] !== null)
        // console.log('!column || !column.required || row[dataIndex] !== null', !column || !column.required || row[column.dataIndex] !== null)
        if (!(!column || !column.required || row.hasOwnProperty(column.dataIndex))) {
          invalidRows.push({ masterCvItemId: row.masterCvItemId, commentKey: column.dataIndex });
        }
        return !column || !column.required || row.hasOwnProperty(column.dataIndex);
      })
    );

    // Display error if required columns are not selected
    if (!isCvLightSelected) {
      toast.error(`Please select the colors to continue!`);
      setInvalidRowKeys(invalidRows)
      return;
    }

    const isFormValid = dataSource.every(row => {
      const keysWithValue = Object.keys(row).filter(key => key.startsWith('masterCvLightId-') && row[key] !== null && row[key] !== undefined);
      // Generate comments keys based on the IDs from keysWithValue
      const commentsKeys = keysWithValue.map(key => {
        const splitId = key.split("-")[1];
        return `comments-${splitId}`;
      });
      // const commentsKeys = Object.keys(row).filter(key => key.startsWith('comments-'));
      return commentsKeys.every(commentKey => {
        const comment = row[commentKey];
        if (!comment) {
          const colorIdKey = `masterCvLightId-${commentKey.split("-")[1]}`;
          const colorId = row[colorIdKey];
          const itemId = row.masterCvItemId;
          const color = categoryData.master_cv_lights.find(c => c.id === colorId);
          const item = categoryData.master_cv_items.find(i => i.id === itemId);
          const isItemCommentMandatory = item?.isCommentMandatory;
          const isColorCommentMandatory = color?.isCommentMandatory;
          if (!isItemCommentMandatory) {
            return true;
          } else if (isItemCommentMandatory && !isColorCommentMandatory) {
            return true;
          } else {
            invalidRows.push({ masterCvItemId: row.masterCvItemId, commentKey });
            return false;
          }
        } else {
          return true;
        }
      });
    });

    if (!isFormValid) {
      toast.error(`Please write the comments to continue!`);
      setInvalidRowKeys(invalidRows);
      return;
    }

    if (isFormValid) {
      await postData(dataSource);
      setInvalidRowKeys([]);
    }

    setHasDataChanged(false);
  };

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "save",
        text: "SUBMIT",
        disabled: !hasDataChanged,
        visible: permissions && permissions.canCreate && (allowAdding || showButton),
        onClick: function () {
          pushData();
        },
      },
    });
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  // save new row in the data-grid
  const onSave = (e) => {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dataSourceArray = [...dataSource];
        const deleteItemId = e.changes[0].key;
        if (deleteItemId) {
          const deleteFromTable = dataSourceArray.length
            ? dataSourceArray.filter((data) => data.id !== deleteItemId)
            : [];
          setDataSource(deleteFromTable);
        }
      } else {
        const insertMode = e && e.changes[0].type === "insert";
        const updateMode = e && e.changes[0].type === "update";
        if (updateMode) e.changes[0]["data"]["rowEdited"] = true;
        const updatedData = e.changes[0].data;
        let finalData = [];
        if (insertMode) {
          finalData.push(updatedData);
        }
        let finalResult = [];
        if (dataSource && dataSource.length) {
          finalResult = _.unionBy(insertMode ? finalData : updatedData, dataSource);
        } else {
          finalResult.push(updatedData);
        }
        setDataSource(finalResult);
        setHasDataChanged(true);
      }
    }
  };

  const cellPrepared = (e) => {
    const columnIdToFilter = invalidRowKeys.filter(rk => rk.commentKey === e?.column?.dataField)?.map(e => e?.masterCvItemId)
    if (columnIdToFilter.includes(e?.data?.masterCvItemId)) {
      e.cellElement.className += " invalid-cell";
    }
  };
  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo">
          <DataGrid
            id="ManageCVGrid"
            width={"100%"}
            wordWrapEnabled={true}
            // onRowPrepared={rowPrepared}
            onCellPrepared={cellPrepared}
            onToolbarPreparing={onToolbarPreparing}
            showBorders={true}
            onRowValidating={() => setInvalidRowKeys([])}
            // columnAutoWidth={true}
            onSaved={onSave}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            ref={dataGridRef}
            // allowColumnResizing={true}
            disabled={isLoading}
            dataSource={dataSource}
            key={defaultKey ?? "id"}
            keyExpr={defaultKey ?? "id"}>
            <LoadPanel enabled={isLoading} visible={isLoading} />
            <Scrolling showScrollbar="always" mode="standard" />
            {/* <HeaderFilter visible allowSearch /> */}
            <SearchPanel visible />
            <Paging defaultPageSize={25} />
            <Pager visible showNavigationButtons showInfo displayMode={"full"} />
            <Editing
              refreshMode={"repaint"}
              mode="cell"
              allowUpdating={permissions && permissions.canCreate && allowUpdating}
              allowAdding={false}
              allowDeleting={false}
            />
            {dataColumns && dataColumns.length ? dataColumns.map((col) => renderField(col)) : null}
          </DataGrid>
        </div>
      );
    } catch (e) {
      console.log("error from CV", e);
    }
  }, [dataSource, dataColumns, invalidRowKeys, dropDownData, hasDataChanged, isLoading]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker
        setIsDataChanged={setHasDataChanged}
        isDataChanged={hasDataChanged}
      />
      {Comp}
    </React.Fragment>
  );
}
