import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  EmailRule, HeaderFilter,
  Lookup, MasterDetail,
  RequiredRule, Scrolling, Selection
} from 'devextreme-react/data-grid';
import {
  createSanitizeAsyncRule, DEButton
} from '../../../../../utils/services/Helpers';
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea
} from '../../../../../components/CustomDataGridComponents';
import { TagBox } from 'devextreme-react/tag-box';
import MDAlert from '../../../../../components/MDAlert';
import MDTypography from '../../../../../components/MDTypography';
import DetectNavigationBlocker from '../../../../../components/navigationdetector/DetectNavigationBlocker';
import CustomSkelton from '../../../../../components/Skelton/CustomSkelton';
import TextArea from 'devextreme-react/text-area';
import FileUploader from 'devextreme-react/file-uploader';
import { toast } from 'react-toastify';
import request from '../../../../../utils/services/Http';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/moment';
import { useIsLoading } from 'utils/hooks/useIsLoading';
import { Context } from 'utils/context/store/Store';
import { SET_IS_LOADING } from 'utils/context/store/Constants';
import useApprovals from 'utils/hooks/useApprovals';
const allowedMimeTypes = process.env.REACT_APP_ALLOWED_MIME_TYPES.split(',');

const LeverReplacementDetailDataGrid = React.memo(({ leaverData, rows, columns, dropDownData, isLoading, permissions, allowSelection = true, showExpand = false, replacementColumns, isParentSelected = false, action, transactionType = "transaction_leaver", apiCallBack }) => {

  const { setIsLoading } = useIsLoading()
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [comments, setComments] = useState(null);
  const [reactActivationDate, setReactActivationDate] = useState(null);
  const [file, setFile] = useState([]);
  const [{ }, dispatch] = useContext(Context)
  const { cancelRequestBeforeApproval } = useApprovals()

  const dataGridRef = useRef();
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setSelectedRowKeys([])
    setDataSource(rows);
    setDataColumns(columns);

    if (isParentSelected) setSelectedRowKeys([leaverData])

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    setDataColumns(columns)
  }, [columns]);

  function onSelectionChanged({ selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = isParentSelected ? false : selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={function (rowData, value) {

            if (col.dataIndex === "masterOrgDivisionId") {
              rowData['masterOrgEntityId'] = null
              rowData['masterCountryId'] = null
              rowData['masterOrgVerticalId'] = null
            }

            if (col.hasOwnProperty("bindedTo"))
              rowData[col.bindedTo] = null;
            this.defaultSetCellValue(rowData, value);
          }}>
          {
            col.required ? <RequiredRule /> : null
          }
          <Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []}
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        if (col.dataIndex === "masterOrgEntityId") {
          return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.dataIndex === "masterOrgEntityId") {
                rowData['masterCountryId'] = null
                rowData['masterOrgVerticalId'] = null
              }
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getEntitiesByDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterCountryId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.dataIndex === "masterCountryId") {
                rowData['masterOrgVerticalId'] = null
              }
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getCountriesByDivisionOrEntity(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterOrgVerticalId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              rowData['masterOrgVerticalId'] = null
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getVerticalsByEntityAndDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return {
                store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
                filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
              };
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
      }
    }
    else if (col.type === "multi-select") {
      return <Column width={"250"} editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTagThisComp}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}
      >
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean"
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column alignment={"left"} dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        format={"yyyy-MM-dd"}
        // format={'dd-MM-yyyy'}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="end date cannot be less than start date"
            validationCallback={async (e) => {
              if (e && e.data) {
                if (e.data.startDate && e.data.endDate) {
                  return e?.data?.startDate ? e.data.endDate >= e.data.startDate : true
                }
                else
                  return e?.value && e.data?.startDate ? e.value >= e.data.startDate : true
              }

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param props
   * custom component to display multi select box
   **/
  const CustomDTagThisComp = (props) => {

    useEffect(() => { }, [props])

    function onValueChanged(e) {
      props.data.setValue(e.value);
    }

    function onSelectionChanged() {
      props.data.component.updateDimensions();
    }

    const ds = dropDownData && dropDownData.hasOwnProperty(props.data.column.dataField) && dropDownData[props.data.column.dataField]?.length ? dropDownData[props.data.column.dataField] : []

    return <TagBox
      dropDownOptions={{ width: "auto" }}
      dataSource={ds}
      defaultValue={props.data.value}
      valueExpr="id"
      displayExpr={"label"}
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="instantly"
      searchEnabled={true}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged} />;
  };

  const manageActions = async () => {
    if (action === "cancel") await manageCancel()
    if (action === "hold") await manageHold()
    if (action === "undoCancel") await manageUndoCancel()
  }

  const manageCancel = async () => {
    const url = `group/bulk-cancel/request/${isParentSelected ? transactionType : "hc_transaction"}`
    const attachmentName = action === "cancel" ? "cancelAttachment" : "holdAttachment";
    const transactionIds = isParentSelected ? [selectedRowKeys?.[0]?.id] : selectedRowKeys.map((sk) => sk.id)
    const fd = new FormData();
    fd.append('comments', comments)
    if (isParentSelected) fd.append('isParentSelected', isParentSelected)
    if (file?.length) fd.append(attachmentName, file?.[0])
    fd.append('transactionIds', JSON.stringify(transactionIds))
    fd.append('canCancelOverrideUser', true)
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    const res = await request.post(url, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
    if (res) {
      toast.success(res.data.data)
      await apiCallBack()
    }
  }

  const manageUndoCancel = async () => {
    console.log('selectedRowKeys', selectedRowKeys)
    console.log('isParentSelected', isParentSelected)
    const transactionIds = isParentSelected ? [selectedRowKeys?.[0]?.id] : selectedRowKeys.map((sk) => sk.id)
    await cancelRequestBeforeApproval(transactionIds, isParentSelected ? transactionType : "hc_transaction", null, apiCallBack, selectedRowKeys, true, isParentSelected, true)
  }

  const manageHold = async () => {
    const attachmentName = action === "cancel" ? "cancelAttachment" : "holdAttachment";
    const data = isParentSelected ? [selectedRowKeys?.[0]?.id] : selectedRowKeys
    console.log('data', data)
    !isParentSelected
      ? data.map((sk) => {
        sk.transactionType = transactionType
        return sk
      })
      : null
    const fd = new FormData();
    fd.append('transactionType', transactionType)
    if (isParentSelected) fd.append('isParentSelected', isParentSelected)
    fd.append('operation', true)
    fd.append('comments', comments)
    fd.append('reactivationDate', reactActivationDate)
    if (file?.length) fd.append(attachmentName, file?.[0])
    fd.append('data', JSON.stringify(data))
    fd.append('canHoldReactivateForOverrideUser', true)
    const res = await request.post('manage-transaction/bulk-hold', fd, { headers: { 'Content-Type': 'multipart/form-data' } })
    if (res) {
      toast.success(res.data.data)
      await apiCallBack()
    }
  }

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        {hasSelected > 0
          ?
          <React.Fragment>
            <br />
            <MDAlert color="light">

              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? 'item' : 'items'}`}
              </MDTypography>
            </MDAlert>
          </React.Fragment>
          : ''
        }

        <DataGrid id="grid" visible={rows?.length} showBorders={true} columnAutoWidth={true}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key="id" keyExpr="id">
          <Scrolling showScrollbar="always" mode="standard" />

          {
            allowSelection && !isParentSelected ?
              <Selection allowSelectAll={true} mode="multiple" selectAllMode={'page'} showCheckBoxesMode={'always'} />
              : null
          }
          <HeaderFilter visible={true} allowSearch={true} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
          }
          <MasterDetail autoExpandAll={showExpand} enabled={showExpand} component={({ data }) => {
            return rows?.length > 0 ? (
              <LeverReplacementDetailDataGrid
                transactionType={selectedRowKeys.some(s => s.id === data.data.id) ? transactionType : "hc_transaction"}
                action={action}
                leaverData={data.data}
                columns={replacementColumns}
                rows={data.data.headcounts?.filter(s => action === "undoCancel" ? s?.canUndoCancelForOverrideUser === true : s.cancelled !== true && s.hold !== true)}
                showExpand={false}
                isParentSelected={selectedRowKeys.some(s => s.id === data.data.id)}
                apiCallBack={apiCallBack}
              />
            ) : null;
          }} />
        </DataGrid>
      </div>
    } catch (e) {
      console.log('error from Leaver Replacement Detail', e)
    }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, selectedRowKeys, isLoading, isParentSelected, action])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      <CustomSkelton>
        {Comp}
        <br />
        {
          !showExpand && selectedRowKeys.length > 0 && action !== "undoCancel" &&
          <FileUploader required={true} labelText={'Supporting Document'} multiple={false} accept={allowedMimeTypes.join(',')}
            value={file} onValueChanged={(e) => setFile(e.value)} uploadMode={'useButton'} />
        }
        <br />

        {
          !showExpand && selectedRowKeys.length > 0 && action === "hold" &&
          <DateBox label={"Reactivation Date"} displayFormat={"dd-MM-yyyy"}
            showClearButton={true} defaultValue={reactActivationDate} onValueChanged={(e) => setReactActivationDate(e.value ? moment(e.value).format('YYYY-MM-DD') : e.value)}
            type="date" pickerType="calendar" min={new Date()} openOnFieldClick={true} />

        }

        <br />

        {
          !showExpand && selectedRowKeys.length > 0 && action !== "undoCancel" && <TextArea
            value={comments}
            onValueChanged={(e) => setComments(e.value)}
            height={'100'}
            defaultValue={comments}
            label={'Comments'}
            autoResizeEnabled={true}
          />
        }
        <br />
        {
          !showExpand && selectedRowKeys.length > 0 && <div align={'right'}>
            <DEButton disabled={action === "hold" ? comments === null || !selectedRowKeys.length || reactActivationDate === null : action === "undoCancel" ? false : comments === null || !selectedRowKeys.length} type={action === "cancel" || action === "undoCancel" ? "danger" : "success"} text={action === "undoCancel" ? 'Undo Cancel' : action} onClick={manageActions} />
          </div>
        }
      </CustomSkelton>
    </React.Fragment>
  );
})

LeverReplacementDetailDataGrid.displayName = 'LeverReplacementDetailDataGrid';

export default LeverReplacementDetailDataGrid;