import * as React from "react";
import { useEffect, useState } from "react";
import moment from 'moment';
import { isJSONValid } from "utils/services/Helpers";
import { Attachment, Download } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

const HcHistoryTable = ({ recordType, tableHcHistoryData, hcDataAccessValues, hcDropdownData }) => {
  const [hcHistoryData, setHcHistoryData] = useState([]);

  useEffect(() => {
    if (tableHcHistoryData) {
      setHcHistoryData(tableHcHistoryData)
    }

    // cleanup on unmount
    return () => {
      setHcHistoryData([])
    }
  }, []);

  // render freelancer history table 
  const renderFreelancerTable = () => {
    return <table>
      <thead>
        <tr>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Gig</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {hcHistoryData.map((item, idx) => {
          const parsedItem = isJSONValid(item.data)
          return <tr key={idx}>
            <td>{parsedItem.startDate ? moment(parsedItem.startDate).format('DD-MM-YYYY') : null}</td>
            <td>{parsedItem.endDate ? moment(parsedItem.endDate).format('DD-MM-YYYY') : null}</td>
            <td>{hcDropdownData.masterGigId.find((g) => g.id === parsedItem.masterGigId)?.label}</td>
            <td>{parsedItem.updatedAt ? moment(parsedItem.updatedAt).format('DD-MM-YYYY') : null}</td>
          </tr>
        })}
      </tbody>
    </table>
  }

  // render hold/reactivation history table
  const renderHoldReactivationTable = () => {
    return <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Action</th>
          <th>Comment</th>
          <th>Reactivation Date</th>
          <th>Attachment</th>
        </tr>
      </thead>
      <tbody>
        {hcHistoryData.map((item, idx) => {
          const parsedItem = isJSONValid(item.data)
          return <tr key={idx}>
            <td>{parsedItem.updatedAt ? moment(parsedItem.updatedAt).format('DD-MM-YYYY') : null}</td>
            <td>{item?.recordType?.charAt(0)?.toUpperCase() + item?.recordType?.slice(1)}</td>
            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.recordType === "reActivate" ? parsedItem.activation_reason : parsedItem.hold_reason}</td>
            <td>{parsedItem.reactivationDate ? moment(parsedItem.reactivationDate).format('DD-MM-YYYY') : null}</td>
            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
              {
                parsedItem?.holdAttachment && renderFiles([{ fileUrl: parsedItem?.holdAttachment, name: parsedItem?.holdAttachmentName }])
              }
            </td>
          </tr>
        })}
      </tbody>
    </table>
  }

  // render hold/reactivation history table
  const renderCancelTable = () => {
    return <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Comment</th>
          <th>Attachment</th>
        </tr>
      </thead>
      <tbody>
        {hcHistoryData.map((item, idx) => {
          return <tr key={idx}>
            <td>{item.updatedAt ? moment(item.updatedAt).format('DD-MM-YYYY') : null}</td>
            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item?.cancel_reason}</td>
            <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
              {
                item?.cancelAttachment &&
                renderFiles([{ fileUrl: item?.cancelAttachment, name: item?.cancelAttachmentName }])
              }
            </td>
          </tr>
        })}
      </tbody>
    </table>
  }

  // Render HC History Table
  return hcHistoryData ? <div className={'HcHistoryTransactionTable CustomDatagridTable'}>
    {
      recordType === "cancel_process" ? renderCancelTable() : recordType === "permanentToFreeLancer" ? renderFreelancerTable() : renderHoldReactivationTable()
    }
  </div>
    : null
}


const renderFiles = (attachmentFiles) => {
  const text = {
    fontSize: "14px"
  }

  return attachmentFiles && attachmentFiles.length ? (
    <List sx={{ width: '100%', paddingTop: 1, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {attachmentFiles.map((v) => (
        <ListItem
          key={v.id}
          secondaryAction={
            <IconButton aria-label="download" href={v?.fileUrl}>
              <FileDownloadIcon />
            </IconButton>
          }
        >
          <ListItemText primaryTypographyProps={{ style: text }} primary={v?.name} />
        </ListItem>
      ))}
    </List>
  ) : null;
}
export default HcHistoryTable;