import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, { AsyncRule, Button, Column, Export, HeaderFilter, Pager, Paging, RequiredRule, Scrolling, SearchPanel, EmailRule, Editing, MasterDetail, Selection } from 'devextreme-react/data-grid';
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
} from "../../../components/CustomDataGridComponents";
import { createSanitizeAsyncRule, isObjectEmpty } from '../../../utils/services/Helpers';
import { cloneIconClick } from "../../../utils/services/DatagridHelpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { v4 as uuidv4 } from 'uuid';
import ExitManagementForm from '../ExitManagementForm';
import { useNavigate, Link } from 'react-router-dom';
import EmployeeResignation from '../EmployeeResignation';
import { DEButton } from 'utils/services/Helpers';
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import useApprovals from 'utils/hooks/useApprovals';

const CellWithTooltip = ({ data }) => {
  const textLength = data?.businessFeedback ? data?.businessFeedback.split(/\s+/).length : 0;
  const isLongText = textLength > 84 || (data?.businessFeedback && data?.businessFeedback.length > 100);

  return (
    <div className={`custom-cell-content ${isLongText ? 'scrollable' : ''}`}>
      {data?.businessFeedback}
    </div>
  );
};

const ExitManagementDataGrid = React.memo(({ rows, columns, apiToPostData, isLoading, permissions, canUserCreate, masterEmployeeDetail, apiCallBack }) => {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const dataGridRef = useRef();
  const navigate = useNavigate();
  const [addEditMode, setAddEditMode] = useState(false)
  const { bulkApproveOrReject } = useApprovals()
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setSelectedRowKeys([])
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    // if (rows && rows.length <= 0 && columns && columns.length) {
    //   setTimeout(() => addRow(dataGridRef, onInitNewRow), 200)
    // }
    setDataColumns(columns)
  }, [columns]);

  /**
   * @param col
   * function use to handle rendering of fields
   **/
  function renderField(col) {
    if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button name="view" icon={'eye'} component={(props) => {
          return <>
            {
              permissions && (permissions.canCreate || permissions.canView)
                ?
                <Link to={`/views/exit/${props.data.data.id}`} state={props.data.data}>
                  <DEButton stylingMode={"contained"} type={"normal"} text={"View"} />
                </Link>
                : null
            }
          </>
        }}
        />
      </Column>
    }
    else if (col.type === "date") {
      return <Column alignment={"left"} dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        format={"yyyy-MM-dd"}
        // format={'dd-MM-yyyy'}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="end date cannot be less than start date"
            validationCallback={async (e) => {
              if (e && e.data) {
                if (e.data.startDate && e.data.endDate) {
                  return e?.data?.startDate ? e.data.endDate >= e.data.startDate : true
                }
                else
                  return e?.value && e.data?.startDate ? e.value >= e.data.startDate : true
              }

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column cellRender={(props) => <CellWithTooltip data={props?.data} />} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string") {
      return (
        <Column
          key={col.dataIndex}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={(data) => {
            if (col.dataIndex === "reqCode") {
              return (
                <a
                  href={data.data.redirectLink}
                  style={{ textDecoration: 'underline !important' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.data.reqCode}
                </a>
              );
            }
            return data.text;
          }}
        >
          {col.dataIndex === "email" && <EmailRule />}
          {col.required && <RequiredRule />}
          <AsyncRule {...sanitizeAsyncRule} />
        </Column>
      );
    }
    else if (col.type === "button") {
      return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} cellComponent={(props) => <>{props?.data?.data?.[col.dataIndex] && <a href={props?.data?.data?.[col.dataIndex]} target={"_blank"} title={`Download ${col.title}`}><FileDownloadIcon /></a>}</>}>
        {col.required ? <RequiredRule /> : null}
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  const handleBulkApprove = async () => {
    const needApprovalHcData = selectedRowKeys?.filter(obj => obj.needApproval) ?? []
    if (needApprovalHcData?.length) {
      await bulkApproveOrReject(true, _.map(needApprovalHcData, 'id'), 'transaction_exit', 10, setSelectedRowKeys, apiCallBack, 'needApproval', false, selectedRowKeys)
    }
  }

  /**
 * Handle Bulk Reject Requests
 **/
  const handleBulkReject = async () => {
      const needApprovalHcData = selectedRowKeys?.filter(obj => obj.needApproval) ?? []
      if (needApprovalHcData?.length) {
        await bulkApproveOrReject(false, _.map(needApprovalHcData, 'id'), 'transaction_exit', 10, setSelectedRowKeys, apiCallBack)
      }
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        disabled: !canUserCreate || dataSource?.some(e => e.newRow),
        visible: permissions.canCreate && canUserCreate,
        onClick: () => navigate('/views/manage-exit')
      }
    },
    );
  }

  function onSaved(e) {
    if (e && e.changes.length) setHasDataChanged(true)
    setAutoWidth(true)
    setAddEditMode(false)
    console.log("saved from ExitManagementDataGrid", e);
  }

  function onRowPrepared(e) {
    if (e.data && (e.data.hasOwnProperty('cancelled') && e.data.cancelled === true || e?.data?.cStatus === 'Closed')) {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " cancelled";
    }
    if (e.data && e.data.hasOwnProperty('needApproval') && e.data.needApproval) {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " needApproval";
    } else if (e.data && e.data.hasOwnProperty('status') && e.data.status && !e?.data?.cancelled && e?.data?.dStatus.toLowerCase() !== 'In Progress'.toLowerCase()) {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " approved";
    }
    else if (e.data && e.data.hasOwnProperty('status') && e.data.status === false && !e?.data?.cancelled && !e?.data?.isDraft) {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " rejected";
    }
  }

  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }
  const hasSelected = selectedRowKeys.length > 0;

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">

        {hasSelected > 0
          ?
          <React.Fragment>
            <br />
            <MDAlert color="light">
              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
              </MDTypography>
              <div style={{ height: '30px', borderLeft: '2px solid #999', margin: '0 10px' }} />
              <MDBox>
                <Grid container spacing={2}>
                  {
                    permissions && permissions.canApprove && selectedRowKeys.some(e => e.needApproval)
                      ? <Grid item>
                        <DEButton stylingMode={"contained"} type={"success"} text={"Approve"} onClick={handleBulkApprove} /> &nbsp;
                        <DEButton stylingMode={"contained"} type={"danger"} text={"Reject"} onClick={handleBulkReject} />
                      </Grid>
                      : null
                  }
                </Grid>
              </MDBox>
            </MDAlert>
          </React.Fragment>
          : null
        }

        <DataGrid id="grid" showBorders={true} onCellValueChanged={() => setHasDataChanged(true)}
          showColumnLines={true} showRowLines={true} onRowPrepared={onRowPrepared}
          ref={dataGridRef} allowColumnResizing={true} onSaved={onSaved} onSelectionChanged={onSelectionChanged}
          disabled={isLoading} dataSource={dataSource} key="id" keyExpr="id" onToolbarPreparing={onToolbarPreparing}>
          <Paging defaultPageSize={25} />
          <Selection
            allowSelectAll
            mode="multiple"
            selectAllMode={'allPages'}
            showCheckBoxesMode={'always'}
          />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          {
            addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
          }
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d)) : null
          }
          <MasterDetail autoExpandAll={false} enabled={false} component={(props) => {
            return <EmployeeResignation masterEmployeeDetail={masterEmployeeDetail} dropdownData={{}} onSubmit={apiToPostData} />
            // <ExitManagementForm apiToPostData={apiToPostData} columns={columns?.filter(c => c.is_form_visible) ?? []} permissions={permissions} data={props.data.data} />
          }} />
        </DataGrid>
      </div>
    }
    catch (e) {
      console.log('error from Exit Management Data grid', e)
    }
  }, [dataSource, dataColumns, hasDataChanged, selectedRowKeys, isLoading, autoWidth, addEditMode])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {Comp}
    </React.Fragment>
  );
})

ExitManagementDataGrid.displayName = 'ExitManagementDataGrid';
export default ExitManagementDataGrid