import React, { useEffect, useRef, useState } from 'react';
import { Form, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import {
  FormDateBox,
  FormFileUploader,
  FormTagBox,
  FormTextArea, FormTextBox, ProgressSteps
} from '../../components/CustomDataGridComponents';
import {
  checkIfValueChanged,
  DEButton, sanitizeInput
} from '../../utils/services/Helpers';
import MDBox from '../../components/MDBox';

const ExitManagementForm = React.memo(({columns, data, permissions, apiToPostData}) => {

  const [formClassName, setFormClassName] = useState(null)
  const [formData, setFormData] = useState({})
  const [formErrorMsg, setFormErrorMsg] = useState(null)
  const formRef = useRef(null)
  const [fieldToHighlights, setFieldsToHighlights] = useState(data?.fieldToHighlights ?? {})
  const originalData = data?.originalRecord ? { ...data?.originalRecord} : { ...data }
  const originalFormData = useRef(originalData);

  useEffect(() => {
    console.log('data', data)
    if (Object.keys(data).length) {
      setFormData(data)
      let formClass = null
      if (data.hasOwnProperty('cancelled') && data.cancelled) formClass = "cancelled"
      else if (data.hasOwnProperty('needApproval') && data.needApproval) formClass = "needApproval"
      else if (data.hasOwnProperty('status') && data.status) formClass = "approved"
      setFormClassName(formClass)
    }
    // cleanup on unmount
    return () => {
      localStorage.removeItem('resize')
      setFormData({})
      setFormClassName(null)
    }
  }, [])

  useEffect(() => {
    console.log('formData', formData)
  }, [formData])

  /**
   * Handle form fields data change
   **/
  const formFieldDataChanged = (e) => {
    let formDataCopy = formData
    setFormData(prevState => {
      const updatedObject = { ...prevState, ...formDataCopy }
      updatedObject[e.dataField] = e.value
      return updatedObject
    })
  }

  /**
   * @param columns
   * use to handle rendering of form fields
   **/
  const renderFormFields = (columns) => {
    return columns.map((col, index) => {
      if (col.type === 'select')
      {
        return (<SimpleItem key={index} name={col.dataIndex}
                      editorType="dxSelectBox"
                      dataField={col.dataIndex}
                      isRequired={col.required}
                      visible={col.is_form_visible}
                      editorOptions={{
                        elementAttr: {
                          class: fieldToHighlights?.[col.dataIndex]
                        },
                        disabled: col.disabled || !(formData.newRow || (!formData.hasRollback && !formData.isRollback && formData.isDraft) || (formData.hasRollback && formData.isRollback) || (formData?.canInitiatorEditAfterApproval)),
                        showClearButton: true,
                        searchEnabled: true,
                        dataSource: {
                          paginate: true,
                          pageSize: 10,
                          store: dropDownData?.[col.dataIndex] ?? []
                        },
                        valueExpr: "id",
                        displayExpr: "label",
                      }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>)
      }
      else if (col.type === 'multi-select') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
                        dataField={col.dataIndex}
                        isRequired={col.required}
                        visible={col.is_form_visible}
                        component={(props) => FormTagBox(props, formData, dropDownData, col.editable)}
                        editorOptions={{
                          elementAttr: {
                            class: fieldToHighlights?.[col.dataIndex]
                          },
                          disabled: col.disabled,
                          setValue: (e, dataIndex) => {
                            formData[dataIndex] = e
                            setFormData(formData)
                            setTimeout(() => {
                              if (!formData?.newRow && !formData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                            }, 500)

                          }
                        }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
      }
      else if (col.type === 'toggle') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
                      editorType="dxSwitch"
                      dataField={col.dataIndex}
                      isRequired={col.required}
                      visible={col.is_form_visible}
                      editorOptions={{
                        elementAttr: {
                          class: fieldToHighlights?.[col.dataIndex]
                        },
                        disabled: col.disabled || !(formData.newRow || (!formData.hasRollback && !formData.isRollback && formData.isDraft) || (formData.hasRollback && formData.isRollback) || (formData?.canInitiatorEditAfterApproval)),
                      }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else if (col.type === 'checkbox') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
                        editorType="dxCheckBox"
                        dataField={col.dataIndex}
                        isRequired={col.required}
                        visible={col.is_form_visible}
                        editorOptions={{
                          elementAttr: {
                            class: fieldToHighlights?.[col.dataIndex]
                          },
                          disabled: col.disabled || !(formData.newRow || (!formData.hasRollback && !formData.isRollback && formData.isDraft) || (formData.hasRollback && formData.isRollback) || (formData?.canInitiatorEditAfterApproval))
                        }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      else if (col.type === 'date') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
                        dataField={col.dataIndex}
                        isRequired={col.required}
                        visible={col.is_form_visible}
                        component={(props) => FormDateBox(props, formData, col.editable)}
                        editorOptions={{
                          elementAttr: {
                            class: fieldToHighlights?.[col.dataIndex]
                          },
                          disabled: col.disabled,
                          setValue: (e, dataIndex) => {
                            const d = { ...formData }
                            if (!formData?.newRow && !formData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                            formData[dataIndex] = e
                            setFormData(prevState => {
                              const updatedObject = { ...prevState, ...d }
                              updatedObject[dataIndex] = e
                              return updatedObject
                            })

                          }
                        }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      else if (col.type === 'int') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
                        editorType="dxNumberBox"
                        dataField={col.dataIndex}
                        isRequired={col.required}
                        visible={col.is_form_visible}
                        editorOptions={{
                          elementAttr: {
                            class: fieldToHighlights?.[col.dataIndex]
                          },
                          disabled: col.disabled || !(formData.newRow || (!formData.hasRollback && !formData.isRollback && formData.isDraft) || (formData.hasRollback && formData.isRollback) || (formData?.canInitiatorEditAfterApproval)),
                          showSpinButtons: true,
                          min: 0
                        }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
      }
      else if (col.type === 'file') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
                      dataField={col.dataIndex}
                      isRequired={isInternal ? false : col.required}
                      visible={col.is_form_visible}
                      component={(props) => FormFileUploader(props, formData)}
                      editorOptions={{
                        elementAttr: {
                          class: fieldToHighlights?.[col.dataIndex]
                        },
                        disabled: formData?.canInitiatorEditAfterApproval ? false : col.disabled || !(formData.newRow || (!formData.hasRollback && !formData.isRollback && formData.isDraft) || (formData.hasRollback && formData.isRollback)),
                        setValue: (e, dataIndex) => {
                          formData[dataIndex] = e
                          if (formData?.canInitiatorEditAfterApproval) formData.editedAfterApproval = true
                          setFormData(formData)
                          // setTimeout(() => {
                          //   if (!formData?.newRow && !formData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                          // },500)
                        }
                      }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else if (col.type === 'textarea') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
                        dataField={col.dataIndex}
                        isRequired={col.required}
                        visible={col.is_form_visible}
                        component={(props) => FormTextArea(props, formData, col.editable)}
                        editorOptions={{
                          elementAttr: {
                            class: fieldToHighlights?.[col.dataIndex]
                          },
                          disabled: col.disabled || !(formData.newRow || (!formData.hasRollback && !formData.isRollback && formData.isDraft) || (formData.hasRollback && formData.isRollback) || (formData?.canInitiatorEditAfterApproval)),
                          setValue: (e, dataIndex) => {
                            const d = { ...formData }
                            if (!formData?.newRow && !formData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                            formData[dataIndex] = e
                            setFormData(prevState => {
                              const updatedObject = { ...prevState, ...d }
                              
                              updatedObject[dataIndex] = e
                              return updatedObject
                            })

                          }
                        }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
                        editorType="dxTextBox"
                        dataField={col.dataIndex}
                        isRequired={col.required}
                        visible={col.is_form_visible}
                        component={(props) => FormTextBox(props, formData, col.editable)}
                        editorOptions={{
                          elementAttr: {
                            class: fieldToHighlights?.[col.dataIndex]
                          },
                          disabled: col.disabled,
                          setValue: (e, dataIndex) => {
                            const d = { ...formData }
                            if (!formData?.newRow && !formData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                            formData[dataIndex] = e
                            setFormData(prevState => {
                              const updatedObject = { ...prevState, ...d }
                              
                              updatedObject[dataIndex] = e
                              return updatedObject
                            })
                          }
                        }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
    })
  }

  /**
   * use to handle form submit
   **/
  const handleSubmit = () => {
    if (formRef.current.instance.validate().isValid) {
      if (formData.reason && !sanitizeInput(formData.reason)) setFormErrorMsg("Invalid characters detected. Please remove any special characters from Justification.")

      let fn = async () => await apiToPostData(formData)
      fn()
    }
  }

  /**
   * use to handle form cancel
   **/
  const handleReset = () => {
    setFormData(prevState => {
      const updatedObject = { ...prevState }
      updatedObject['reason'] = null
      updatedObject['lastWorkingDay'] = null
      return updatedObject
    })

    formRef.current.instance.resetValues()
  }

  return (
    <React.Fragment>
      {columns?.length ?
        <MDBox id="HcFormContainer">
          {data?.steps?.length ?
            <div style={{ padding: "16px" }}>
              <MDBox pt={2} pb={1}>
                <ProgressSteps steps={data.steps} />
              </MDBox>
            </div>
            : null
          }
          <div style={{ padding: "16px" }} className={formClassName}>
            <Form id="HcForm" className="HcFormContent" key="id" ref={formRef}
                  onFieldDataChanged={formFieldDataChanged}
                  formData={formData}
                  style={{ marginBottom: "30px" }}>
              <GroupItem colCount={2}>
                {columns?.length > 0 && renderFormFields(columns)}
              </GroupItem>
            </Form>
            {formErrorMsg !== null ? <MDBox className="form-error">{formErrorMsg}</MDBox> : null}
            <MDBox style={{ textAlign: "right" }}>
              {
                formData.newRow
                  ? <>
                    <DEButton visible={permissions && permissions.canCreate} stylingMode={"contained"} type={"success"} text={"Submit"} onClick={handleSubmit} style={{ marginLeft: "10px" }} />
                    <DEButton visible={permissions && permissions.canCreate} stylingMode={"contained"} type={"danger"} text={"Reset"} onClick={handleReset} style={{ marginLeft: "10px" }} />
                  </>
                  : null
              }
            </MDBox>
          </div>
        </MDBox>
        : null
      }
    </React.Fragment>
  )
})

ExitManagementForm.displayName = 'ExitManagementForm'
export default ExitManagementForm