import BaseLayOut from '../../components/Layout/BaseLayOut';
import useExitManagement from '../../utils/hooks/useExitManagement';
import { useEffect } from 'react';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import EmployeeResignation from './EmployeeResignation';
import { useParams } from 'react-router-dom';

export default function ExitManagement({ routeKey, pageName }) {

    const { transactionExitId } = useParams();
    const { getEmployeeExitRecord, createOrUpdateExitRecords, cancelExitRequest, empExitDetails, isLoading } = useExitManagement(routeKey);
    const { getPermissionsForPage } = usePagePermissions(routeKey);

    useEffect(() => {
        const fn = async () => await getEmployeeExitRecord(transactionExitId);
        fn();
    }, [transactionExitId]); // Add transactionExitId to dependencies

    return (
        <BaseLayOut pageTitle={pageName ?? ""}>
            <EmployeeResignation 
                resignationData={empExitDetails?.rows} 
                masterEmployeeDetail={empExitDetails?.masterEmployeeDetail} 
                dropdownData={empExitDetails?.dropdownData} 
                onSubmit={createOrUpdateExitRecords} 
                onCancel={cancelExitRequest} 
                apiCallBack={getEmployeeExitRecord} 
            />
        </BaseLayOut>
    );
}