import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  EmailRule,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
} from "../../../components/CustomDataGridComponents";
import { addRow, handleDeleteRequest, isObjectEmpty, getLevels, DEButton, hasDuplicates, createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import MDAlert from "../../../components/MDAlert";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { cloneIconClick } from "../../../utils/services/DatagridHelpers";
import { Context } from "../../../utils/context/store/Store";
import { SET_STRUCTURE_MANAGEMENT } from "../../../utils/context/store/Constants";
import { toast } from "react-toastify";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import { TagBox } from "devextreme-react/tag-box";
import BulkUploaderModal from "../../../components/Modal/BulkUploader/BulkUploaderModal";
const _ = require('lodash')

export default function EmployeeDataGrid({ data, rows, columns, dropDownData, isLoading, showButton = true, permissions, allowAdding = true, allowSelection = false, allowDeleting = true, postData, valueToFetch, handleDelete, allowDeletingFromApi = true, bulkUploadApi, uploadTemplateLink, orgStructureLink, apiCallback, tableName }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false)
  const [{ employee }, dispatch] = useContext(Context)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { }, [employee]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    if (rows && rows.length <= 0 && columns && columns.length) {
      setTimeout(() => addRow(dataGridRef, onInitNewRow), 200)
    }
    setDataColumns(columns)
  }, [columns]);

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  async function onEditorPreparing(e) {

    if (e && e.dataField === "masterOrgEntityId" || e.dataField === "masterCountryId" || e.dataField === "masterOrgVerticalId" || e.dataField === "masterOrgFunctionId" || e.dataField === "masterLevelId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

  }

  /**
 * @param e
 * function executed on datagrid cell click
 **/
  function handleCellClick(e) { }

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        const allLevels = dropDownData && dropDownData.hasOwnProperty('masterLevelId') ? dropDownData['masterLevelId'] : []
        const vet = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') && dropDownData['masterOrgVerticalId']?.length ? dropDownData['masterOrgVerticalId'] : []
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={function (rowData, value) {

            if (col.dataIndex === "masterOrgDivisionId") {
              rowData['masterOrgEntityId'] = null
              rowData['masterCountryId'] = null
              rowData['masterOrgVerticalId'] = null
              rowData['masterOrgFunctionId'] = null
              rowData['masterLevelId'] = null
              rowData['masterOrgHfmId'] = null
            }

            if (col.hasOwnProperty("bindedTo"))
              rowData[col.bindedTo] = null;
            this.defaultSetCellValue(rowData, value);
          }}>
          {
            col.required ? <RequiredRule /> : null
          }
          {
            col.dataIndex === "masterLevelId"
              ? <Lookup allowClearing dataSource={(options) => {
                return getLevelsLocal(options)
              }} displayExpr="label" valueExpr="id" />
              : <Lookup allowClearing dataSource={(options) => {
                const data = {
                  store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
                  paginate: true,
                  pageSize: 10
                }
                return data;
              }} displayExpr="label" valueExpr="id" />
          }
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        if (col.dataIndex === "masterOrgEntityId") {
          return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              rowData['masterCountryId'] = null
              rowData['masterOrgVerticalId'] = null
              rowData['masterOrgFunctionId'] = null
              rowData['masterLevelId'] = []
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getEntitiesByDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterCountryId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              rowData['masterOrgVerticalId'] = null
              rowData['masterOrgFunctionId'] = null
              rowData['masterLevelId'] = []
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getCountriesByDivisionOrEntity(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterOrgVerticalId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              rowData['masterOrgFunctionId'] = null
              rowData['masterLevelId'] = []
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getVerticalsByEntityAndDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return {
                store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
                filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
              };
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
      }
    }
    else if (col.type === "multi-select") {
      return <Column width={"250"} editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTagThisComp}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}
      >
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean"
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true} />
        <Button hint="Clone" icon="copy" visible={(e) => permissions && permissions.canCreate} onClick={(e) => cloneIconClick(e, dataSource, setDataSource, false, false, true)} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column dataType={"date"} format={'dd-MM-yyyy'} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_Fsortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" || col.dataIndex === "llid" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param props
   * custom component to display multi select box
   **/
  const CustomDTagThisComp = (props) => {

    useEffect(() => { }, [props])

    function onValueChanged(e) {
      props.data.setValue(e.value);
    }

    function onSelectionChanged() {
      props.data.component.updateDimensions();
    }

    const ds = dropDownData && dropDownData.hasOwnProperty('countries') && dropDownData['countries']?.length ? dropDownData['countries'] : []

    return <TagBox
      dropDownOptions={{ width: "auto" }}
      dataSource={ds}
      defaultValue={props.data.value}
      valueExpr="id"
      displayExpr={"label"}
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="instantly"
      searchEnabled={true}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged} />;
  };

  /**
   * @param options
   * function use get verticals based on division and entity
   **/
  function getVerticalsByEntityAndDivision(options) {
    let uniqueVerticals = []

    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null && options.data.hasOwnProperty('masterCountryId') && options.data.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId === null && options.data.hasOwnProperty('masterCountryId') && options.data.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('id') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (isObjectEmpty(options)) {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') ? dropDownData['masterOrgVerticalId'] : []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }

    // if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId !== null && options.data.masterCountryId !== null)
    // {
    //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.uniqueKey === `${options.data?.masterOrgDivisionId} - ${options.data?.masterOrgEntityId} - ${options.data?.masterCountryId}`) ?? []
    //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    // }
    // else if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId !== null && options.data.masterCountryId === null)
    // {
    //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
    //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    // }
    // else if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId === null &&  options.data.masterCountryId !== null)
    // {
    //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
    //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    // }
    // else if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId === null && options.data.masterCountryId === null)
    // {
    //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
    //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    // }
    // else
    // {
    //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
    //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['uniqueKey'], item])).values()];
    // }

    return uniqueVerticals.length > 0 ? uniqueVerticals.sort((a, b) => a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())) : []

  }

  /**
   * @param options
   * function use get countries by division or entity
   **/
  function getCountriesByDivisionOrEntity(options) {
    let uniqueCountries = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.uniqueKey === `${options.data.masterOrgDivisionId} - ${options.data.masterOrgEntityId}`) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && !options.data.hasOwnProperty('masterOrgEntityId') || options.data?.masterOrgEntityId === null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterCountryId') ? dropDownData['masterCountryId'] : []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }

    return uniqueCountries.length > 0 ? uniqueCountries.sort((a, b) => a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())) : []

  }

  /**
   * @param options
   * function use get Entities by division
   **/
  function getEntitiesByDivision(options) {
    let uniqueEntities = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null) {
      const filteredEntities = dropDownData['masterOrgEntityId']?.filter(d => d.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()];
    }
    else {
      const filteredEntities = dropDownData && dropDownData.hasOwnProperty('masterOrgEntityId') ? dropDownData['masterOrgEntityId'] : []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()];
    }

    return uniqueEntities.length > 0 ? uniqueEntities.sort((a, b) => a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())) : []

  }

  /**
   * @param options
   * get levels by division entity country and vertical
   **/
  function getLevelsLocal(options) {
    let uniqueEntities = []
    const allLevels = dropDownData && dropDownData.hasOwnProperty('masterLevelId') ? dropDownData['masterLevelId'] : []
    if (options && options.data && options.data.masterOrgDivisionId !== null) {
      const vet = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') && dropDownData['masterOrgVerticalId']?.length ? dropDownData['masterOrgVerticalId'] : []
      uniqueEntities = getLevels(allLevels, vet, options.data.masterOrgDivisionId, options.data.masterOrgEntityId, options.data.masterCountryId, options.data.masterOrgVerticalId, dropDownData.hasOwnProperty('levels') ? dropDownData['levels'] : [], dropDownData.hasOwnProperty('LIds') ? dropDownData['LIds'] : [])
    }
    else {
      uniqueEntities = [...new Map(allLevels?.map(item => [item['id'], item])).values()];
    }

    return uniqueEntities.length > 0 ? uniqueEntities.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0)
    e.data.approved = true
    e.data.status = true
    e.data.newRow = true
    if (valueToFetch === "level")
      e.data.approved = true

    if (valueToFetch === "decvl") {
      e.data.masterOrgDivisionId = null
      e.data.masterOrgEntityId = null
      e.data.masterCountryId = null
      e.data.masterOrgVerticalId = null
    }

    setAutoWidth(false)
    setAddEditMode(true)
  }

  /**
* function use to call the api to post data
**/
  const pushData = async () => {
    let newData = [...dataSource]
    const hasCode = dataColumns.some(obj => obj.dataIndex === "code");
    const hasEmail = dataColumns.some(obj => obj.dataIndex === "email");
    const hasLLID = dataColumns.some(obj => obj.dataIndex === "llid");

    if (hasEmail && hasDuplicates(newData, 'email')) toast.error(`Duplicate entry found for EMAIL. It cannot be duplicated!`)
    else if (hasLLID && hasDuplicates(newData, 'llid')) toast.error(`Duplicate entry found for LLID. It cannot be duplicated!`)
    else
    {
      await postData(dataSource)
      setHasDataChanged(false)
    }
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "upload",
          text: "BULK UPLOAD",
          visible: permissions && permissions.canCreate && (allowAdding || showButton),
          onClick: function () { setBulkUploadModalVisible(true) },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: permissions && permissions.canCreate && (allowAdding || showButton),
          onClick: function () { pushData() },
        }
      }
    );
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dsCopy = [...dataSource]
        const filteredDs = dsCopy.length ? dsCopy.filter(ds => ds.id !== e.changes[0].key) : []
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs)
          setHasDataChanged(true)
        }
        else {
          setDataSource([])
          setHasDataChanged(false)
        }
      }
      else {
        if (e && e.changes[0].type === "update")
          e.changes[0]["data"]["rowEdited"] = true
        const dd = e.changes[0].data;
        let finalData = []
        finalData.push(dd)
        let result = []
        if (dataSource && dataSource.length) {
          result = _.unionBy(finalData, dataSource);
        }
        else result.push(dd)

        setDataSource(result)
        setHasDataChanged(true)

      }
    }
    setAutoWidth(true)
    setAddEditMode(false)
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    if (e && e.isValid) {
      if (e.newData) {
        const d = [...dataSource]

        if (valueToFetch === "decvl") {
          const { masterLevelId, masterOrgDivisionId, masterOrgEntityId, masterCountryId, masterOrgVerticalId } = e.newData
          const f = d.filter(s => s.id !== e.key && s.masterLevelId === masterLevelId && s.masterOrgDivisionId === masterOrgDivisionId && s.masterOrgEntityId === masterOrgEntityId && s.masterCountryId === masterCountryId && s.masterOrgVerticalId === masterOrgVerticalId)
          if (f && f.length) {
            e.isValid = false;
            e.errorText = "Duplicate Combination Found"
          }
        }

        if (valueToFetch === "level") {
          const f = d.filter(s => s.id !== e.key && s.code === e.newData.code && s.name === e.newData.name)
          if (f && f.length) {
            e.isValid = false;
            e.errorText = "Duplicate Combination Found for Code - Name"
          }
        }

        if (valueToFetch === "employee") {
          const f = d.filter(s => s.id !== e.key && s.email === e.newData.email)
          if (f && f.length) {
            e.isValid = false;
            e.errorText = "Duplicate Combination Found for Employee"
          }
        }
      }
    }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    const newRecords = dataSource.filter(ds => ds.hasOwnProperty('newRow'))
    let newData = [...dataSource]
    handleDeleteRequest(async () => {
      const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
      const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))
      const result = deleteFromApi.map(a => a.id);
      if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
        await handleDelete(result, valueToFetch)
      }
      else if (deleteFromApi && deleteFromApi.length > 0) {
        employee.rows = newRecords
        employee.apiDelete = true
        dispatch({
          type: SET_STRUCTURE_MANAGEMENT,
          payload: employee
        })
        await handleDelete(result, valueToFetch)
      }
      else if (deleteFromTable && deleteFromTable.length > 0) {
        deleteFromTable.map(a => {
          newData = newData.filter((item) => item.key !== a.key);
        })
        setDataSource(newData)
      }
      setHasDataChanged(false)
      setSelectedRowKeys([])
    })
  };

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        {hasSelected > 0
          ?
          <React.Fragment>
            <br />
            <MDAlert color="light">

              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
              </MDTypography>

              <Divider orientation="vertical" color="dark" flexItem />

              <MDBox>
                <Grid container spacing={2}>
                  {
                    permissions && permissions.canDelete && allowDeletingFromApi
                      ? <Grid item >
                        <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                      </Grid> : null
                  }
                </Grid >
              </MDBox >
            </MDAlert >
          </React.Fragment>
          : ""
        }

        <BulkUploaderModal title={"Employee - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={orgStructureLink} />

        <DataGrid id="grid"
          onCellClick={handleCellClick}
          onToolbarPreparing={onToolbarPreparing}
          showBorders={true}
          columnAutoWidth={false} onSaved={onSave}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} onInitNewRow={onInitNewRow}
          onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key="id" keyExpr="id"
          onEditorPreparing={onEditorPreparing} onRowValidating={onRowValidating}>
          <Paging defaultPageSize={15} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          {
            addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
          }

          {
            allowSelection ?
              <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
              : null
          }
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={permissions && permissions.canCreate} allowAdding={permissions && permissions.canCreate} allowDeleting={allowDeleting} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
          }
        </DataGrid>
      </div>
    }
    catch (e) {
    }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, selectedRowKeys, data, isLoading, autoWidth, addEditMode, employee, bulkUploadModalVisible])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {Comp}
    </React.Fragment>
  );
}