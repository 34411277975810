import BaseLayOut from '../../components/Layout/BaseLayOut';
import ExitManagementDataGrid from './DataTable/ExitManagementDataGrid';
import useExitManagement from '../../utils/hooks/useExitManagement';
import { useEffect } from 'react';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import EmployeeResignation from './EmployeeResignation';

export default function ExitManagement({ routeKey, pageName }) {

  const { getAllExitRecords, createOrUpdateExitRecords, exitDetails, isLoading } = useExitManagement(routeKey)
  const { getPermissionsForPage } = usePagePermissions(routeKey)

  useEffect(() => {
    const fn = async () => await getAllExitRecords()
    fn()
  }, [])
  return <BaseLayOut pageTitle={pageName}>
    <ExitManagementDataGrid allowEditing={true} columns={exitDetails?.columns} rows={exitDetails?.rows} permissions={getPermissionsForPage()} apiToPostData={createOrUpdateExitRecords} canUserCreate={exitDetails.canUserCreate} masterEmployeeDetail={exitDetails.masterEmployeeDetail} apiCallBack={getAllExitRecords} />
  </BaseLayOut>
}