import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Export,
  HeaderFilter,
  Lookup,
  MasterDetail,
  RequiredRule,
  SearchPanel,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import "./styles.scss";
import useHeadcountRequest from "../../../utils/hooks/useHeadcountRequest";
import { Context } from "../../../utils/context/store/Store";
import { SET_HC_JD_VIEW } from "../../../utils/context/store/Constants";
import {
  CustomDateBox,
  CustomDTag,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
  CustomTextBox,
  SelectJD,
} from "../../../components/CustomDataGridComponents";
import {
  DEButton,
  getLevels,
  isObjectEmpty,
} from "../../../utils/services/Helpers";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import { renderAttachment } from "../../../utils/services/DatagridHelpers";
import { useParams, useLocation } from "react-router-dom";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import HcFormsView from '../HcForms/HcFormsView'
const _ = require('lodash')

const HcDataGrid = React.memo(({ data, rows, columns, orgColumns, dropDownData, masterRequestTypeId, masterHeadcountTypeId, isLoading, showButton = true, routeKey, permissions, allowAdding = true, allowUpdating = true, allowSelection = false, allowDeleting = true, disableSubmitButton = false, manageWidth = null, apiCallBack = null, manageDataChange = null, appFields = [], internalEmpData, isCloned = false, showActionColumn = true, isDraft = false, groupId = null, isInternal, manageSetFormEditMode = null, setDgData = [], isForInternalMobility = false }) => {

  const param = useParams()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const hcId = queryParams.get('id')
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [addNewItem, setAddNewItem] = useState(0)
  const [onRowExpand, setOnRowExpand] = useState(false)
  const [{ headcountRequest }, dispatch] = useContext(Context);
  const dataGridRef = useRef();
  const { updateOrCreateHeadcountRequest } = useHeadcountRequest(routeKey);
  const [addEditMode, setAddEditMode] = useState(false)
  const [formEditMode, setFormEditMode] = useState(false)

  useEffect(() => {
    //if (isCloned) setHasDataChanged(true)
    setDataSource(rows);
    if (internalEmpData === null) setDgData(rows)
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      dispatch({
        type: SET_HC_JD_VIEW,
        payload: []
      })
      setDataSource([])
      if (internalEmpData === null)
        setDgData([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => {
    if (dataSource.length <= 0 && columns && columns.length) initializeRow()
  }, [dataSource]);
  useEffect(() => { }, [param]);
  useEffect(() => {
    setDataSource(rows)
    if (internalEmpData === null)
      setDgData(rows)
    // below check is of no use since we are using useNavigate for the routing
    /*if (isCloned) setDataSource(dataSource.length ? dataSource : rows)
    else setDataSource(rows)*/
  }, [rows]);
  useEffect(() => {
    // if (!isCloned && data && isObjectEmpty(param) && rows && rows.length <= 0 && columns && columns.length) {
    //   setTimeout(() => addRow(dataGridRef, onInitNewRow), 200)
    // }
    setDataColumns(columns)
  }, [columns]);
  useEffect(() => { }, [headcountRequest]);
  useEffect(() => {
    // if (isObjectEmpty(param) && rows && rows.length <= 0 && columns && columns.length) {
    //   setTimeout(() => addRow(dataGridRef, onInitNewRow), 200)
    // }

    if (columns && columns.length && localStorage.getItem('isInternal') === "true") {
      const updatedcolumns = [...columns]
      updatedcolumns.forEach((c) => {
        c.editable = true
      })
      setDataColumns(updatedcolumns)
    }
  }, [addEditMode]);
  useEffect(() => {
    if (hasDataChanged) {
      setAddEditMode(false)
      setFormEditMode(false)
      setTimeout(() => {
        dataGridRef.current.instance.collapseAll(-1)
      }, 250)
    }
  }, [hasDataChanged])
  useEffect(() => {
    if (dataGridRef.current && dataSource.length > 0 && dataGridRef.current.instance && !isForInternalMobility) {

      if (hcId) {
        console.log("hcId", hcId)
        dataGridRef.current.instance.collapseAll(-1)
        dataGridRef.current.instance.expandRow(parseInt(hcId));
      }
      else {
        const clonedDs = [...dataSource]
        const rolledBackIds = clonedDs?.filter(ds => ds.isRollback === true) ?? []
        const ids = rolledBackIds?.length ? _.map(rolledBackIds, 'id') : []
        if (ids?.length) {
          dataGridRef.current.instance.collapseAll(-1)
          ids?.map(id => dataGridRef.current.instance.expandRow(id));
        }
        else {
          dataGridRef.current.instance.collapseAll(-1)
          dataGridRef.current.instance.expandRow(dataSource[0].id);
        }
      }
    }
  }, [addNewItem])

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(dataColumns, dropDownData) {
    return dataColumns.map((col, index) => {
      if (col.type === "select") {
        if (!col.hasOwnProperty("filtrationKey")) {
          return <Column alignment={"left"} key={index} allowEditing={col.dataIndex === "gig" ? true : col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
            setCellValue={function (rowData, value) {
              if (col.dataIndex === "masterOrgDivisionId") {
                dispatch({ type: SET_HC_JD_VIEW, payload: [] })
                rowData['masterOrgEntityId'] = null
                rowData['masterCountryId'] = null
                rowData['masterOrgVerticalId'] = null
                rowData['masterOrgFunctionId'] = null
                if (!isInternal)
                  rowData['masterLevelId'] = null;
              }
              if (col.dataIndex === "masterJobTypeId") {
                let gridInstance = dataGridRef.current.instance;
                let editRowKey = gridInstance.option("editing.editRowKey");
                let index = gridInstance.getRowIndexByKey(editRowKey);
                const jobType = gridInstance.cellValue(index, "masterJobTypeId");
                const st = gridInstance.cellValue(index, "cStatus");
                const status = st?.toLowerCase() === "Approved".toLowerCase() || st?.toLowerCase() === "Closed".toLowerCase()
                if (!status) {
                  if (jobType !== value) {
                    if (value === 2) {
                      rowData['hireDate'] = null
                      rowData['masterManagementTypeId'] = null
                      rowData['billable'] = false
                      rowData['critical'] = false
                      rowData['justification'] = null
                    }
                    if (value === 1) {
                      rowData['masterGigId'] = null
                      rowData['startDate'] = null
                      rowData['endDate'] = null
                      rowData['billable'] = false
                      rowData['justification'] = null
                    }
                  }
                }
                if (status) {
                  rowData['pToF'] = false
                  rowData['wantsToConvertToFreelancer'] = jobType !== value
                }
              }
              if (col.dataIndex === "masterRecruiterId") {
                let gridInstance = dataGridRef.current.instance;
                let editRowKey = gridInstance.option("editing.editRowKey");
                let index = gridInstance.getRowIndexByKey(editRowKey);
                const msId = gridInstance.cellValue(index, "masterRecruiterId");
                const status = gridInstance.cellValue(index, "dStatus");
                if (msId && status) {
                  if (msId !== value)
                    rowData['recruiterChanged'] = true
                }
              }
              if (col.dataIndex === "masterLevelId") {
                if (!isInternal)
                  rowData['masterLevelId'] = null
                setTimeout(() => {
                  dispatch({ type: SET_HC_JD_VIEW, payload: [] })
                }, 1000)
              }

              if (col.dataIndex === "masterBudgetStatusId") {
                if (value !== 2) {
                  rowData['masterCurrencyId'] = null
                  rowData['budget'] = null
                }
              }
              if (col.hasOwnProperty("bindedTo"))
                rowData[col.bindedTo] = null;
              this.defaultSetCellValue(rowData, value);
            }}>
            {
              col.required ? <RequiredRule /> : null
            }
            {/*{
              col.dataIndex === "masterLevelId" && col.required ? <AsyncRule
                message={"No JD found with the combination"}
                validationCallback={async (e) => {
                  if (e && e.data && e.data.hasOwnProperty('jd_transaction')) {
                    return true
                  }
                  else {
                    let gridInstance = dataGridRef.current.instance;
                    let editRowKey = gridInstance.option("editing.editRowKey");
                    let index = gridInstance.getRowIndexByKey(editRowKey);
                    const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
                    const masterOrgEntityId = gridInstance.cellValue(index, "masterOrgEntityId");
                    const masterCountryId = gridInstance.cellValue(index, "masterCountryId");
                    const masterOrgVerticalId = gridInstance.cellValue(index, "masterOrgVerticalId");
                    const masterOrgFunctionId = gridInstance.cellValue(index, "masterOrgFunctionId");
                    const status = gridInstance.cellValue(index, "dStatus");
                    if (masterOrgDivisionId && masterOrgVerticalId && masterOrgFunctionId) {
                      const combination = { masterOrgDivisionId, masterOrgFunctionId, masterOrgVerticalId, masterLevelId: e.value }

                      if (masterOrgEntityId) {
                        combination['masterOrgEntityId'] = masterOrgEntityId
                      }
                      if (masterCountryId) {
                        combination['masterCountryId'] = masterCountryId
                      }
                      if (hcJdView && isObjectEmpty(hcJdView)) {
                        hasJd = await getJDByCombination(combination, false, null);
                      }
                      else {
                      }

                      if ((typeof hasJd === "object" && Object.keys(hasJd).length) || (typeof hcJdView === "object" && Object.keys(hcJdView).length)) {
                        jdObj.transactionJdId = hasJd.transactionJdId
                        jdObj.job_name = hasJd.job_name
                        jdObj.view_jd = hasJd.job_name
                        jdObj.hasJd = true
                        e.data['minRange'] = hasJd?.level?.minRange
                        e.data['transactionJdId'] = hasJd.transactionJdId
                        e.data['hasJd'] = true
                        return true
                      }
                      else {
                        delete e.data['minRange']
                        delete e.data['transactionJdId']
                        e.data['hasJd'] = false
                        dispatch({
                          type: SET_HC_JD_VIEW,
                          payload: []
                        })
                        return true
                      }
                    }
                    else {
                      return false
                    }
                  }

                }} /> : null
            }*/}
            {
              col.dataIndex === "masterLevelId"
                ? <Lookup allowClearing dataSource={(options) => {
                  return getLevelsLocal(options)
                }} displayExpr="label" valueExpr="id" />
                : <Lookup allowClearing dataSource={(options) => {
                  const data = {
                    store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
                    paginate: true,
                    pageSize: 10
                  }
                  return data;
                }} displayExpr="label" valueExpr="id" />
            }
          </Column>;
        }
        else if (col.hasOwnProperty("filtrationKey")) {
          if (col.dataIndex === "masterOrgVerticalId") {
            return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
              allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
                rowData['masterOrgVerticalId'] = null
                rowData['masterOrgFunctionId'] = null
                if (!isInternal)
                  rowData['masterLevelId'] = null
                this.defaultSetCellValue(rowData, value);
                if (col.hasOwnProperty("bindedTo")) {
                  rowData[col.bindedTo] = null;
                }
                dispatch({ type: SET_HC_JD_VIEW, payload: [] })
              }}>
              <Lookup allowClearing dataSource={(options) => {
                return getVerticalsByEntityAndDivision(options)
              }} displayExpr="label" valueExpr="id" />
              {
                col.required ? <RequiredRule /> : null
              }
            </Column>;
          }
          else if (col.dataIndex === "masterOrgFunctionId") {
            return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
              allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
                rowData['masterOrgFunctionId'] = null
                if (!isInternal)
                  rowData['masterLevelId'] = null
                this.defaultSetCellValue(rowData, value);
                if (col.hasOwnProperty("bindedTo")) {
                  rowData[col.bindedTo] = null;
                }
                dispatch({ type: SET_HC_JD_VIEW, payload: [] })
              }}>
              <Lookup allowClearing dataSource={(options) => {
                return getFunctionsByVerticals(options)
              }} displayExpr="label" valueExpr="id" />
              {
                col.required ? <RequiredRule /> : null
              }
            </Column>;
          }
          else if (col.dataIndex === "masterCountryId") {
            col.required = true
            return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
              allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
                this.defaultSetCellValue(rowData, value);
                if (col.dataIndex === "masterCountryId") {
                  if (!isInternal)
                    rowData['masterLevelId'] = null
                  rowData['masterOrgVerticalId'] = null
                  rowData['masterOrgFunctionId'] = null
                }
                if (col.hasOwnProperty("bindedTo")) {
                  rowData[col.bindedTo] = null;
                }
                dispatch({ type: SET_HC_JD_VIEW, payload: [] })
              }}>
              <Lookup allowClearing dataSource={(options) => {
                return getCountriesByDivisionOrEntity(options)
              }} displayExpr="label" valueExpr="id" />
              {
                col.required ? <RequiredRule /> : null
              }
            </Column>;
          }
          else if (col.dataIndex === "masterOrgEntityId") {
            col.required = true
            return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
              allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
                this.defaultSetCellValue(rowData, value);
                if (col.dataIndex === "masterOrgEntityId") {
                  if (!isInternal)
                    rowData['masterLevelId'] = null
                  rowData['masterCountryId'] = null
                  rowData['masterOrgVerticalId'] = null
                  rowData['masterOrgFunctionId'] = null
                }
                if (col.hasOwnProperty("bindedTo")) {
                  rowData[col.bindedTo] = null;
                }
                dispatch({ type: SET_HC_JD_VIEW, payload: [] })
              }}>
              <Lookup allowClearing dataSource={(options) => {
                return getEntitiesByDivision(options)
              }} displayExpr="label" valueExpr="id" />
              {
                col.required ? <RequiredRule /> : null
              }
            </Column>;
          }
          else {
            return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
              allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
                this.defaultSetCellValue(rowData, value);
                if (col.hasOwnProperty("bindedTo")) {
                  rowData[col.bindedTo] = null;
                }
              }}>
              <Lookup allowClearing dataSource={(options) => {
                return {
                  store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
                  filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
                };
              }} displayExpr="label" valueExpr="id" />
              {
                col.required ? <RequiredRule /> : null
              }
            </Column>;
          }
        }
      }
      else if (col.type === "multi-select") {
        return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          editCellComponent={CustomDTag}
          cellTemplate={(container, options) => {
            if (!addEditMode) {
              const noBreakSpace = "\u00A0";
              const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
              container.textContent = text || noBreakSpace;
              container.title = text;
            }
          }}
          calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
            if (target === "search" && typeof (filterValue) === "string") {
              return [col.dataIndex, "contains", filterValue];
            }
            return function (data) {
              return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
            };
          }}>
          {
            col.required ? <RequiredRule /> : null
          }
          <Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.type === "checkbox") {
        return <Column alignment={"center"} key={index} dataType="boolean"
          showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
          allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
          caption={col.title} setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value)
          }}>
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
      else if (col.type === "actions") {
        return <Column alignment={"center"} width={"auto"} key={index} allowEditing={col.editable} visible={false} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
          type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false}>
          <Button name="delete"
            visible={(e) => e.visible = !!(e && e.row && e.row.data && e.row.data.newRow)}
            disabled={(e) => e.disabled = hasDataChanged || !showButton || isCloned ? false : true}
            component={(props) => <DEButton hint="Delete" stylingMode={"contained"} type={"danger"} icon={"trash"} />}
          />
        </Column>
      }
      else if (col.type === "date") {
        return <Column alignment={"left"} key={index} dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
          allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} format={'dd-MM-yyyy'}
          caption={col.title}>
          {
            col.required ? <RequiredRule /> : null
          }
          {
            col.dataIndex === "endDate" ? <AsyncRule
              message="end date cannot be less than start date"
              validationCallback={async (e) => {
                if (e && e.data) {
                  if (e.data.startDate && e.data.endDate) {
                    return e.data.endDate >= e.data.startDate
                  }
                  else
                    return e.value >= e.data.startDate
                }
              }}
            /> : null
          }
        </Column>;
      }
      else if (col.type === "int") {
        return <Column alignment={"left"} key={index} dataType={col.type} allowEditing={col.editable} visible={col.is_visible}
          allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
          caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} isForHc={true} />}>
          {
            col.required ? <RequiredRule /> : null
          }
          {
            col.dataIndex !== "id"
              ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
                return e && e.value && e.value.toString().length <= 15
              }} />
              : null
          }
        </Column>;
      }
      else if (col.type === "button") {
        return <Column alignment={"left"} key={index} allowEditing={true} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} editCellComponent={(props) => <SelectJD props={props.data} canEdit={col.editable} />} >
          <RequiredRule />
        </Column>;
      }
      else if (col.type === "file") {
        return <Column alignment={"left"} key={index} showInColumnChooser={false} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} fixed={false} dataField={col.dataIndex} caption={col.title} cellRender={renderAttachment}
          editCellComponent={CustomFileInput} />
        {
          col.required ? <RequiredRule /> : null
        }
      }
      else if (col.type === "textarea") {
        return <Column alignment={"left"} key={index} cssClass={"textAreaColumnWrap"} width={!onRowExpand ? "350" : ""} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>
      }
      else if (col.type === "text") {
        return <Column alignment={"left"} key={index} editCellComponent={CustomTextBox} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} cssClass={"WrappedColumnClass"}>
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>
      }
      else {
        if (col.dataIndex === "job_name") {
          return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={showActionColumn ? col.is_visible : false} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
            type={onRowExpand ? "" : "buttons"} dataField={col.dataIndex} caption={col.title} fixed={false}>
            <Button name="Job Title"
              component={(props) => {
                return <a href={`/views/jd/Job-${props?.data?.data?.jobCode ?? props?.data?.data?.jd_transaction?.job_code}/${props?.data?.data?.version ?? props?.data?.data?.jd_transaction?.version}`} target={"_blank"}>
                  <b> <u>{props.data.data.job_name}</u></b>
                </a>
              }}
            />
          </Column>
          {
            col.required ? <RequiredRule /> : null
          }
        }
        else {
          return <Column alignment={"left"} key={index} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }

      }
    })


  }

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  function onEditorPreparing(e) {

    // condition to disable all divisions except for the one of leaver while adding replacement
    if (showButton === false && data) {
      const masterOrgDivisionId = data?.data?.division
      if (e.parentType === "dataRow" && e.dataField === "masterOrgDivisionId") {
        e.editorOptions.dataSource = {
          store: {
            type: "array",
            data: dropDownData && dropDownData.hasOwnProperty('masterOrgDivisionId') ? dropDownData['masterOrgDivisionId'] : [],
          },
          postProcess: function (data) {
            let newData = data.map((x) => {
              x.disabled = false;
              if (x.id !== masterOrgDivisionId) {
                x.disabled = true;
              }
              return x;
            });
            return newData;
          }
        }
      }
    }


    // condition to disable all levels except for the one with the same code of internal mobility employee
    if (isInternal && internalEmpData) {
      const levelCode = dropDownData && dropDownData.hasOwnProperty('masterLevelId') ? dropDownData['masterLevelId']?.filter(e => e.id === internalEmpData?.newHc?.levelId)[0]?.sCode : []
      const filteredLevels = getLevelsLocal(e?.row ?? null) ?? []
      if (e.parentType === "dataRow" && e.dataField === "masterLevelId") {
        e.editorOptions.dataSource = {
          store: {
            type: "array",
            data: filteredLevels
          },
          postProcess: function (data) {
            let newData = data.map((x) => {
              x.disabled = false;
              if (levelCode.localeCompare(x.sCode) === 1) {
                x.disabled = true;
              }
              return x;
            });
            return newData;
          }
        }
      }
    }

    // const disableFields = e && e.row && e.row.data && e.row.data.status && e.row.data?.transaction_headcount_group?.userId === getUserInfo()?.id

    // if (disableFields) {
    //   if (e && e.dataField === "masterOrgDivisionId") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "market") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "masterOrgHfmId") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "clients") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "hireDate") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "masterManagementTypeId") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "masterCurrencyId") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "budget") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "hiringManager") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "critical") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "justification") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "revenue") e.editorOptions.disabled = disableFields
    //   if (e && e.dataField === "masterGigId") e.editorOptions.disabled = false
    // }

    // if (e && e.dataField === "masterOrgEntityId") {
    //   let gridInstance = dataGridRef.current.instance;
    //   let editRowKey = gridInstance.option("editing.editRowKey");
    //   let index = gridInstance.getRowIndexByKey(editRowKey);
    //   const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
    //   if (!masterOrgDivisionId)
    //     e.editorOptions.disabled = true
    //   else
    //     e.editorOptions.disabled = disableFields
    // }

    // if (e && e.dataField === "masterCountryId") {
    //   let gridInstance = dataGridRef.current.instance;
    //   let editRowKey = gridInstance.option("editing.editRowKey");
    //   let index = gridInstance.getRowIndexByKey(editRowKey);
    //   const masterOrgEntityId = gridInstance.cellValue(index, "masterOrgEntityId");
    //   if (!masterOrgEntityId)
    //     e.editorOptions.disabled = true
    //   else
    //     e.editorOptions.disabled = disableFields
    // }

    // if (e && e.dataField === "masterOrgVerticalId") {
    //   let gridInstance = dataGridRef.current.instance;
    //   let editRowKey = gridInstance.option("editing.editRowKey");
    //   let index = gridInstance.getRowIndexByKey(editRowKey);
    //   const masterCountryId = gridInstance.cellValue(index, "masterCountryId");
    //   if (!masterCountryId)
    //     e.editorOptions.disabled = true
    //   else
    //     e.editorOptions.disabled = disableFields
    // }

    // if (e && e.dataField === "billable") {
    //   let gridInstance = dataGridRef.current.instance;
    //   let editRowKey = gridInstance.option("editing.editRowKey");
    //   let index = gridInstance.getRowIndexByKey(editRowKey);
    //   const masterJobTypeId = gridInstance.cellValue(index, "masterJobTypeId");
    //   if (!masterJobTypeId)
    //     e.editorOptions.disabled = true
    //   else
    //     e.editorOptions.disabled = false
    // }

    // if (e && e.dataField === "hiringManager") {
    //   let gridInstance = dataGridRef.current.instance;
    //   let editRowKey = gridInstance.option("editing.editRowKey");
    //   let index = gridInstance.getRowIndexByKey(editRowKey);
    //   const masterJobTypeId = gridInstance.cellValue(index, "masterJobTypeId");
    //   if (!masterJobTypeId)
    //     e.editorOptions.disabled = true
    //   else
    //     e.editorOptions.disabled = false
    // }

    // if (e && e.dataField === "masterOrgFunctionId") {
    //   let gridInstance = dataGridRef.current.instance;
    //   let editRowKey = gridInstance.option("editing.editRowKey");
    //   let index = gridInstance.getRowIndexByKey(editRowKey);
    //   const masterOrgVerticalId = gridInstance.cellValue(index, "masterOrgVerticalId");
    //   if (!masterOrgVerticalId)
    //     e.editorOptions.disabled = true
    //   else
    //     e.editorOptions.disabled = disableFields
    // }

    // if (e && e.dataField === "masterLevelId") {
    //   let gridInstance = dataGridRef.current.instance;
    //   let editRowKey = gridInstance.option("editing.editRowKey");
    //   let index = gridInstance.getRowIndexByKey(editRowKey);
    //   const masterOrgFunctionId = gridInstance.cellValue(index, "masterOrgFunctionId");
    //   const jd_transaction = gridInstance.cellValue(index, "jd_transaction");
    //   // if (isInternal)
    //   //   e.editorOptions.disabled = true
    //   // else {
    //   if (!masterOrgFunctionId)
    //     e.editorOptions.disabled = true
    //   else
    //     disableFields ? e.editorOptions.readOnly = disableFields : e.editorOptions.disabled = false
    //   // }
    // }

    // if (!disableFields) {
    //   if (e && e.dataField === "masterJobTypeId" || e.dataField === "revenue" || e.dataField === "market" || e.dataField === "masterCurrencyId" || e.dataField === "master_clients" || e.dataField === "justification" || e.dataField === "masterOrgHfmId") {
    //     let gridInstance = dataGridRef.current.instance;
    //     let editRowKey = gridInstance.option("editing.editRowKey");
    //     let index = gridInstance.getRowIndexByKey(editRowKey);
    //     const masterLevelId = gridInstance.cellValue(index, "masterLevelId");
    //     if (!masterLevelId) {
    //       e.editorOptions.disabled = true
    //     }
    //     else e.editorOptions.disabled = false
    //   }
    // }

    /*if (e && e.dataField === "master_clients") {
            e.editorName = "dxTagBox";
          e.editorOptions.dataSource = dropDownData[e.dataField];
          e.editorOptions.showSelectionControls = true;
          e.editorOptions.displayExpr = "label";
          e.editorOptions.valueExpr = "id";
          e.editorOptions.value = e.value || [];
          e.editorOptions.onValueChanged = function (args) {
            e.setValue(args.value);
      };
    }*/

  }

  /**
   * @param options
   * function use get verticals based on division and entity
   **/
  function getVerticalsByEntityAndDivision(options) {
    let uniqueVerticals = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null && options.data.hasOwnProperty('masterCountryId') && options.data.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId === null && options.data.hasOwnProperty('masterCountryId') && options.data.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (isObjectEmpty(options)) {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') ? dropDownData['masterOrgVerticalId'] : []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }

    return uniqueVerticals.length > 0 ? uniqueVerticals.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []

  }

  /**
   * @param options
   * function use get functions based on verticals
   **/
  function getFunctionsByVerticals(options) {
    let allUniqueValues = []
    if (isObjectEmpty(options)) {
      const filteredCap = dropDownData && dropDownData.hasOwnProperty('masterOrgFunctionId') ? dropDownData['masterOrgFunctionId'] : []
      allUniqueValues = [...new Map(filteredCap?.map(item => [item['masterOrgFunctionId'], item])).values()];
    }
    else {
      const filteredCap = dropDownData['masterOrgFunctionId'].filter(cap => cap.masterOrgVerticalId === options?.data?.masterOrgVerticalId && cap.masterOrgDivisionId === options?.data?.masterOrgDivisionId && cap.masterOrgEntityId === options?.data?.masterOrgEntityId && cap.masterCountryId === options?.data?.masterCountryId) ?? []
      allUniqueValues = [...new Map(filteredCap?.map(item => [item['masterOrgFunctionId'], item])).values()];

    }
    return allUniqueValues.length > 0 ? allUniqueValues.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []
  }

  /**
   * @param options
   * function use get countries by division or entity
   **/
  function getCountriesByDivisionOrEntity(options) {
    let uniqueCountries = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId && obj.masterOrgEntityId === options.data?.masterOrgEntityId) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && !options.data.hasOwnProperty('masterOrgEntityId') || options.data?.masterOrgEntityId === null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterCountryId') ? dropDownData['masterCountryId'] : []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }

    return uniqueCountries.length > 0 ? uniqueCountries.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []

  }

  /**
   * @param options
   * function use get Entities by division
   **/
  function getEntitiesByDivision(options) {
    let uniqueEntities = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null) {
      const filteredEntities = dropDownData['masterOrgEntityId']?.filter(d => d.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()];
    }
    else {
      const filteredEntities = dropDownData && dropDownData.hasOwnProperty('masterOrgEntityId') ? dropDownData['masterOrgEntityId'] : []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()];
    }

    return uniqueEntities.length > 0 ? uniqueEntities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  /**
   * @param options
   * get levels by division entity country and vertical
   **/
  function getLevelsLocal(options) {
    let uniqueEntities = []
    const allLevels = dropDownData && dropDownData.hasOwnProperty('masterLevelId') ? dropDownData['masterLevelId'] : []
    if (options && options.data && options.data.masterOrgDivisionId !== null) {
      const vet = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') && dropDownData['masterOrgVerticalId']?.length ? dropDownData['masterOrgVerticalId'] : []
      uniqueEntities = getLevels(allLevels, vet, options.data.masterOrgDivisionId, options.data.masterOrgEntityId, options.data.masterCountryId, options.data.masterOrgVerticalId, dropDownData.hasOwnProperty('levels') ? dropDownData['levels'] : [], dropDownData.hasOwnProperty('LIds') ? dropDownData['LIds'] : [])
    }
    else {
      uniqueEntities = [...new Map(allLevels?.map(item => [item['id'], item])).values()];
    }

    return uniqueEntities.length > 0 ? uniqueEntities.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []
  }


  function initializeRow() {
    dataSource.length ?
      setDataSource([{
        id: uuidv4(),
        newRow: true,
        billable: false,
        critical: false,
        dStatus: false,
        attachment: null,
        onHold: false,
        isCancelled: false,
        re_activate: false,
        pToF: false,
        masterRequestTypeId: masterRequestTypeId,
        masterHeadcountTypeId: masterHeadcountTypeId,
        masterOrgDivisionId: null,
        masterOrgEntityId: null,
        masterCountryId: null,
        masterOrgVerticalId: null,
        masterOrgFunctionId: null,
        masterLevelId: null,
        masterRecruitmentStatusId: 1,
        ...(isDraft ? { isDraft: true, transactionHeadcountGroupId: rows?.find(obj => 'transactionHeadcountGroupId' in obj)?.transactionHeadcountGroupId || null } : {})
      },
      ...dataSource])
      : setDataSource([{
        id: uuidv4(),
        newRow: true,
        billable: false,
        critical: false,
        dStatus: false,
        attachment: null,
        onHold: false,
        isCancelled: false,
        re_activate: false,
        pToF: false,
        masterRequestTypeId: masterRequestTypeId,
        masterHeadcountTypeId: masterHeadcountTypeId,
        masterOrgDivisionId: null,
        masterOrgEntityId: null,
        masterCountryId: null,
        masterOrgVerticalId: null,
        masterOrgFunctionId: null,
        masterLevelId: null,
        masterRecruitmentStatusId: 1,
        ...(isDraft ? { isDraft: true, transactionHeadcountGroupId: rows?.find(obj => 'transactionHeadcountGroupId' in obj)?.transactionHeadcountGroupId || null } : {})
      }])
    // MasterDetail autoExpandAll is true (expanded by defualt)
    setOnRowExpand(true)
    setHasDataChanged(false)
    setAddNewItem(addNewItem + 1)
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          disabled: dataSource.some(e => !e.masterOrgDivisionId),
          visible: dataSource.some(e => e.isRollback) ? false : isForInternalMobility || isInternal ? false : permissions && permissions.canCreate && (isDraft || !groupId),
          onClick: () => {
            initializeRow()
            localStorage.removeItem('HC')
            localStorage.removeItem('LR')
          }
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SAVE AS DRAFT",
          disabled: addEditMode ? true : !(hasDataChanged || isDraft && !dataSource.some(e => !e.masterOrgDivisionId)),
          visible: !disableSubmitButton && (isDraft || !groupId) && !isInternal,
          onClick: async () => {
            if (
              (isCloned && dataSource.some(e => e.hasOwnProperty('attachment') && (e.attachment === null || (e.attachment && e.attachment?.length === 0))))
              ||
              (isDraft && dataSource.some(e => e.hasOwnProperty('attachments') && (
                (e.attachment === null || (Array.isArray(e.attachment) && e.attachment.length === 0)) &&
                (e.attachments && e.attachments?.every(att => att.hasOwnProperty('isDeleted') && att.isDeleted))))
              )
            ) {
              toast.error("Entry is missing attachments. Kindly attach files to proceed!")
            }
            else {
              if (dataSource.some(s => s.masterOrgDivisionId === null || s.masterOrgEntityId === null || s.masterCountryId === null || s.masterOrgVerticalId === null || s.masterOrgFunctionId === null)) {
                return toast.error("Please fill all the required fields")
              }
              else {
                setHasDataChanged(false)
                await updateOrCreateHeadcountRequest(dataSource, masterRequestTypeId, masterHeadcountTypeId, null, true)
              }
            }
          }
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: addEditMode ? true : isDraft ? !(hasDataChanged || isDraft && !dataSource.some(e => !e.masterOrgDivisionId)) : (!hasDataChanged && !dataSource?.some(d => !d?.isDraft && d?.canInitiatorEditAfterApproval && d?.fieldToHighlights && Object?.keys(d?.fieldToHighlights)?.length > 0 && d.editedAfterApproval)),
          visible: !disableSubmitButton && (isDraft || !groupId) || !dataSource.some(e => e.hasOwnProperty('needApproval') && e.needApproval),
          onClick: async () => {
            if (
              (isCloned && dataSource.some(e => e.hasOwnProperty('attachment') && (e.attachment === null || (e.attachment && e.attachment?.length === 0))))
              ||
              (isDraft && dataSource.some(e => e.hasOwnProperty('attachments') && (
                (e.attachment === null || (Array.isArray(e.attachment) && e.attachment.length === 0)) &&
                (e.attachments && e.attachments?.every(att => att.hasOwnProperty('isDeleted') && att.isDeleted))))
              )
            )
              toast.error("Entry is missing attachments. Kindly attach files to proceed!")
            else {
              if (dataSource.some(s => s.masterOrgDivisionId === null || s.masterOrgEntityId === null || s.masterCountryId === null || s.masterOrgVerticalId === null || s.masterOrgFunctionId === null)) {
                return toast.error("Please fill all the required fields")
              }
              else {
                setHasDataChanged(false)
                await updateOrCreateHeadcountRequest(dataSource, masterRequestTypeId, masterHeadcountTypeId, internalEmpData, false)
              }
            }
          }
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: `Count: ${dataSource && dataSource.length}`,
          disabled: true,
          visible: !showButton
        }
      },
    );
  }

  return (
    <>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {
        !showButton && data ? <MDBox pt={2} pl={2} pr={2} pb={1} bgColor={"white"}><MDTypography variant="h6" fontWeight="medium">HC Replacement Details</MDTypography></MDBox> : null
      }
      <div id="HcDataGridContainer">
        <DataGrid sorting={{ mode: 'none' }} id="HcDataGrid"
          className={formEditMode ? "HcDataGridForm" : ''}
          renderAsync={formEditMode ? true : false}
          columnMinWidth={150}
          onToolbarPreparing={onToolbarPreparing} showBorders={true}
          onRowCollapsed={(e) => {
            e?.component?.collapseRow(e?.key);
            setOnRowExpand(false)
          }}
          showColumnLines={true} showRowLines={true}
          columnAutoWidth={true}
          rowAlternationEnabled={true}
          ref={dataGridRef} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key="id" keyExpr="id"
          onEditorPreparing={onEditorPreparing}>
          <HeaderFilter visible={false} allowSearch={true} />
          <SearchPanel visible={false} />
          <Export enabled={true} allowExportSelectedData={true} />
          <MasterDetail autoExpandAll={false} enabled={!isForInternalMobility} component={(props) => {
            return <HcFormsView data={props.data.data} hasDataChanged={hasDataChanged} columns={columns} dropDownData={dropDownData} masterTransactionType={"hc_transaction"} permissions={permissions} dataSource={dataSource} setDataSource={setDataSource} setHasDataChanged={setHasDataChanged} routeKey={routeKey} apiCallBack={apiCallBack} setDgData={setDgData} isDraft={isDraft} isCloned={isCloned} isInternal={isInternal} internalEmpData={internalEmpData} headcountRequest={headcountRequest} />
          }} />
          {
            dataColumns?.length > 0 && renderField(dataColumns, dropDownData)
          }
        </DataGrid >
      </div >
    </>
  );
})

HcDataGrid.displayName = "HcDataGrid"


export default HcDataGrid
